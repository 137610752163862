<template>
  <div id="inspire">
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="grey darken-3"
      dark
    >
      <div>
        <v-avatar color="red">
          <v-img
            src="@/assets/logo tuvoto.png"
            max-width="100"
            @click="toDashboard"
          ></v-img>
        </v-avatar>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn text @click="logout">salir</v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  props: {
    source: String
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
    toDashboard() {
      if (this.$router.currentRoute.path != "/dashboard")
        this.$router.push("/dashboard");
    }
  }
};
</script>
