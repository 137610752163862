<template>
  <v-app>
    <component v-bind:is="layout"></component>
  </v-app>
</template>

<script>
import ApplicationLayout from "./views/layouts/Application";
import SimpleLayout from "./views/layouts/Simple";
export default {
  computed: {
    layout() {
      return this.$store.getters.layout;
    }
  },
  components: {
    "application-layout": ApplicationLayout,
    "simple-layout": SimpleLayout
    // define as many layouts you want for the application
  }
};
</script>
