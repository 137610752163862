import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/elections/:id",
    name: "PublicElectionResults",
    component: () =>
      import(/* webpackChunkName: "publicelectionresults" */ "../views/public_elections/Results.vue")
  },
  {
    path: "/confirmation/:token",
    name: "ConfirmationEmails",
    component: () =>
      import(/* webpackChunkName: "confirmationemails" */ "../views/ConfirmationEmail.vue")
  },
  {
    path: "/vote/:token",
    name: "ElectionVote",
    component: () =>
      import(/* webpackChunkName: "vote" */ "../views/Vote.vue")
  },
  {
    path: "/thanks/:id",
    name: "Thanks",
    component: () =>
      import(/* webpackChunkName: "thanks" */ "../views/Thanks.vue")
  },
  {
    path: "/user/:token/activate",
    name: "UserActivation",
    component: () =>
      import(/* webpackChunkName: "useractivation" */ "../views/users/Activation.vue")
  },
  {
    path: "/wizard/new",
    name: "WizardNew",
    component: () =>
      import(/* webpackChunkName: "wizardnew" */ "../views/wizard/New.vue")
  },
  {
    path: "/wizard/:id/edit",
    name: "WizardEdit",
    component: () =>
      import(/* webpackChunkName: "wizardedit" */ "../views/wizard/Edit.vue")
  },
  {
    path: "/wizard/:id/questions",
    name: "WizardQuestions",
    component: () =>
      import(/* webpackChunkName: "wizardquestions" */ "../views/wizard/Questions.vue")
  },
  {
    path: "/wizard/:id/features",
    name: "WizardFeatures",
    component: () =>
      import(/* webpackChunkName: "wizardfeatures" */ "../views/wizard/Features.vue")
  },
  {
    path: "/wizard/:id/configuration",
    name: "WizardConfiguration",
    component: () =>
      import(/* webpackChunkName: "wizardconfiguration" */ "../views/wizard/Configuration.vue")
  },
  {
    path: "/wizard/:id/loaddata",
    name: "WizardLoadData",
    component: () =>
      import(/* webpackChunkName: "wizardloaddata" */ "../views/wizard/LoadData.vue")
  },
  {
    path: "/wizard/:id/notification",
    name: "WizardNotification",
    component: () =>
      import(/* webpackChunkName: "wizardnotification" */ "../views/wizard/Notification.vue")
  },
  {
    path: "/wizard/:id/payment",
    name: "WizardPayment",
    component: () =>
      import(/* webpackChunkName: "wizardpayment" */ "../views/wizard/Payment.vue")
  },
  {
    path: "/wizard/:id/results",
    name: "WizardResults",
    component: () =>
      import(/* webpackChunkName: "wizardresults" */ "../views/wizard/Results.vue")
  },
  {
    path: "/Warning",
    name: "Warning",
    component: () =>
      import(/* webpackChunkName: "warning" */ "../views/Warning.vue")
  },
  {
    path: "/preview/:id&:from",
    name: "WizardPreview",
    component: () =>
    import(/* webpackChunkName: "warning" */ "../views/Preview.vue")
  },
  {
    path: "/previewthanks/:id&:from",
    name: "PreviewThanks",
    component: () =>
    import(/* webpackChunkName: "thanks" */ "../views/PreviewThanks.vue")
  },
  {
    path: "/zerozone/elections",
    name: "zeroZoneElections",
    component: () =>
    import(/* webpackChunkName: "zerozoneelections" */ "../views/zero_zone/Elections.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "Home",
    "Login",
    "Register",
    "ElectionVote",
    "Thanks",
    "UserActivation",
    "PublicElectionResults",
    "ConfirmationEmails"
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("auth_token");

  if (publicPages.includes(to.name)) {
    store.commit("SET_LAYOUT", "simple-layout");
  } else {
    store.commit("SET_LAYOUT", "application-layout");
  }
  if (authRequired && !loggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
});