import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: "application-layout"
  },
  getters: {
    layout(state) {
      return state.layout;
    }
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    }
  },
  actions: {},
  modules: {
    auth
  }
});
