<template>
  <v-alert v-if="message" :type="type" :color="getColor" class="mt-5">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: ["message", "type"],
  computed: {
    getColor() {
      if (this.type == "error") {
        return "purple";
      } else {
        return null;
      }
    }
  }
};
</script>