<template>
  <div>
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <v-card class="mx-auto" flat>
      <v-card-subtitle class="pt-10">
        <h1 class="text-center">Servicio universal para votaciones en línea</h1>
        <h1 class="text-center red--text mt-10" v-if="elections.length > 0">
          Votaciones abiertas al público
        </h1>
      </v-card-subtitle>
      <v-card-text>
        <v-card
          v-for="election in elections"
          :key="election.id"
          class="mb-5"
          color="grey lighten-5"
        >
          <v-list-item
            :to="{ name: 'PublicElectionResults', params: { id: election.id } }"
          >
            <v-list-item-content>
              <v-list-item-title>
                <h1>{{ election.name }}</h1>
              </v-list-item-title>
              <v-list-item-subtitle>
                <h2>
                  {{ election.organization.name }} -
                  {{ election.organization.description }}
                </h2>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon color="red" large>mdi-vote</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import elections from "@/services/elections";

export default {
  name: "HelloWorld",
  data: () => ({
    elections: []
  }),
  mounted() {
    let self = this;
    elections.public().then(response => {
      self.elections = response.data;
    });
  }
};
</script>
