<template>
  <div>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="grey darken-3"
      dark
    >
      <div>
        <v-avatar color="red">
          <v-img
            src="@/assets/logo tuvoto.png"
            max-width="100"
            @click="toHome"
          ></v-img>
        </v-avatar>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn text @click="dialog = true">
          ingresar
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

    <div>
      <v-dialog v-model="dialog" width="400" min-width="400">
        <componentLogin @clicked="onRegistrarClick" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import componentLogin from "@/views/Login.vue";

export default {
  data: () => ({
    dialog: false
  }),
  components: {
    componentLogin
  },
  methods: {
    onRegistrarClick(value) {
      this.dialog = value;
    },
    toHome() {
      if (this.$router.currentRoute.path != "/") this.$router.push("/");
    }
  }
};
</script>
