import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueI18n from 'vue-i18n'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);
Vue.use(VueI18n)

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es"
  }
});
