<template>
  <v-card class="pa-8" shaped>
    <v-form @submit.prevent="handleLogin">
      <v-img height="150px" src="@/assets/logo tuvoto.png" contain></v-img>
      <v-card-title class="justify-center">
        <span class="red--text">Iniciar sesión</span>
      </v-card-title>
      <v-card-text>
        <alert :message="error.message" type="error"></alert>
        <v-text-field
          v-model="user.email"
          color="red"
          prepend-icon="mdi-email"
          placeholder="Correo electrónico"
        />
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          v-model="user.password"
          color="red"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          placeholder="Contraseña"
        />
        <v-row class="my-4">
          <v-btn type="submit" color="red" class="white--text" block rounded
            >Iniciar</v-btn
          >
        </v-row>
        <v-row>
          <v-btn
            type="submit"
            @click="handleRegister"
            color="red"
            class="white--text"
            text
            block
            rounded
            >¿No tienes una cuenta?</v-btn
          >
          <!--<a @click="handleRegister" class="button red--text mx-auto">
            <strong>Registrar</strong>
          </a>-->
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import authenticate from "@/services/authenticate";
import alert from "@/components/alert/alert";

export default {
  name: "Login",
  components: { alert },
  data: () => ({
    showPassword: false,
    user: {
      email: null,
      password: null
    },
    error: { message: null }
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    handleLogin() {
      let self = this;
      if (this.user.email && this.user.password) {
        authenticate
          .create(this.user)
          .then(response => {
            if (response.data) {
              self.$store.dispatch("auth/login", response.data).then(() => {
                self.$router.push({ name: "Dashboard" });
              });
            }
          })
          .catch(error => {
            if (error.response) {
              self.error = error.response.data;
            }
          });
      }
    },
    handleRegister() {
      this.$emit("clicked", false);
      this.$router.push("/register");
    }
  }
};
</script>
