export default function authHeader() {
  let token = JSON.parse(localStorage.getItem("auth_token"));

  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  } else {
    return {};
  }
}
