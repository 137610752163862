import axios from "axios";
import authHeader from "./authHeader";

const API = process.env.VUE_APP_API_URL + "/elections";
const API_PUBLIC = process.env.VUE_APP_API_URL + "/public_elections";

//const API_TEMP = process.env.VUE_APP_API_URL + "/election";

export default {
  get() {
    return axios.get(API, {
      headers: authHeader()
    });
  },
  public() {
    return axios.get(API_PUBLIC);
  },
  getVoters(election, options) {
    let query = Object.keys(options)
      .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(options[k]))
      .join("&");
    return axios.get(API + "/" + election.id + "/voters?" + query, {
      headers: authHeader()
    });
  },
  show(id) {
    return axios.get(API + '/' + id, {
      headers: authHeader()
    });
  },
  create(election) {
    return axios.post(API, { "election": election }, {
      headers: authHeader()
    });
  },
  update(election) {
    return axios.put(API + '/' + election.id, { "election": election }, {
      headers: authHeader()
    });
  },
  updateBallot(election) {
    return axios.put(API + '/' + election.id + '/ballots', { "election": election }, {
      headers: authHeader()
    });
  },
  uploadVoters(election, data) {
    return axios.post(API + '/' + election.id + '/voters', data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("auth_token")),
      }
    });
  },
  downloadVotersTemplate(election) {
    return axios.get(API + '/' + election.id + '/voters/template', {
      responseType: 'blob',
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("auth_token"))
      }
    });
  },
  notify(election) {
    return axios.post(API + "/" + election.id + "/notification_votes", {}, {
      headers: authHeader()
    })
  },
  results(election) {
    return axios.get(API + "/" + election.id + "/results", {
      headers: authHeader()
    })
  },
  download(electionId) {
    return axios.get(API + "/" + electionId + "/results_spreadsheets.xls", {
      responseType: 'blob',
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("auth_token"))
      }
    })
  },
  publicResults(electionId) {
    return axios.get(API_PUBLIC + "/" + electionId)
  },
  status(election, options) {
    let query = Object.keys(options)
      .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(options[k]))
      .join("&");
    return axios.get(API + "/" + election.id + "/voter_statuses?" + query, {
      headers: authHeader()
    })
  },
  budget(election) {
    return axios.get(API + "/" + election.id + "/budgets", {
      headers: authHeader()
    })
  },
  yape(election) {
    return axios.post(API + "/" + election.id + "/yapes", {}, {
      headers: authHeader()
    })
  },
  tunki(election) {
    return axios.post(API + "/" + election.id + "/tunkies", {}, {
      headers: authHeader()
    })
  },
  confirmPayment(election, token) {
    return axios.post(API + "/" + election.id + "/payment_confirmations?token=" + token, {}, {
      headers: authHeader()
    })
  },
};